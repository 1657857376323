<template>
  <div>
    <h1 class="text-18 text-gray-700 font-bold mb-5 mt-10 text-center pb-12">
      Authentication Bypass
    </h1>
    <div class="w-11/12 lg:w-6/12 m-auto">
      <div class="flex mb-10">
        <div class="duration-300 w-full">
          <div>
            <label
              class="flex flex-row items-center cursor-pointer py-2 px-4 border grey rounded mr-3"
              style="user-select: none" v-if="canToggle">
              <span class="text-xs text-gray-500 mr-3">
                <span class="duration-300" :class="{'text-sm font-bold text-black': buttonState == 'off' }">Off</span>
                /
                <span class="duration-300" :class="{ 'text-sm font-bold text-black': buttonState == 'on' }">On</span>
              </span>
              <label class="custom-switch">
                <input type="checkbox" class="custom-switch-input" :checked="buttonState == 'on'" @change="toggleSwitch">
                <span class="custom-switch-label"></span>
              </label>
            </label>          
          </div>
        </div>
      </div>
      <modal
        className="w-11/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24" ref="switchModal">
        <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark"/>
        <div class="text-lg font-bold mb-4">
          Success!
        </div>
        <div class="text-12 mb-10">
          Feature has been turned {{ buttonState }}
        </div>
        <div class="flex justify-center">
          <button
            type="button"
            class="button bg-blue-500 hover:bg-blue-600 px-12 text-white mt-2" @click.prevent="closeSwitchModal">
            Close
          </button>
        </div>
      </modal>

      <div class="flex mb-10 ">
        <div class="duration-300 w-full">
          <div>
            <form>
              <div>
                <template v-if="getFormError(form)">
                  <div class="alert alert-red-soft mb-10">
                    <div class="alert-icon mr-3">
                      !
                    </div>
                    <div class="text-xs font-normal">{{ getFormError(form) }}</div>
                  </div>
                </template>
                <template v-else>
                  <div class="h-16"></div>
                </template>
                <form-group
                  label-style="grey"
                  name="email"
                  left-icon="mail-outline"
                  v-model="form.data.email.value"
                  :data="form.data.email"
                >
                  By-Passer Email
                </form-group>

                <form-group
                  label-style="grey"
                  type="number"
                  name="duration"
                  left-icon="lock-closed-outline"
                  v-model="form.data.duration.value"
                  :data="form.data.duration"
                >
                  Duration In Minutes
                </form-group>
                <div class="text-center mb-6">
                  <button  @click="generatePassword" class="btn btn-blue btn-md w-full" :disabled="form.loading">
                    <span v-if="form.loading">Loading...</span>
                    <span v-else>Generate Password</span>
                  </button>
                </div>
              </div>
            </form>
            <modal
              className="w-12/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24" ref="passwordModal">
              <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark"/>
              <div class="text-lg font-bold mb-4">
                Success!
              </div>
              <div class="text-12 mb-10">
                Generated Password
              </div>              
              <div class="text-10 mb-10 bg-purple-100 px-4 py-4 rounded-md">
                {{ password }}
              </div>
              <div class="flex justify-center">
                <button
                  type="button"
                  class="button bg-blue-500 hover:bg-blue-600 px-12 text-white mt-2" @click.prevent="closePasswordModal">
                  Close
                </button>
              </div>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      buttonState: 'off',
      password: '',
      form: this.$options.basicForm([
        {
          name: 'email',
          rules: 'required|email'
        },
        {
          name: 'duration',
          rules: 'nullable|numeric'
        },
      ]),

    };
  },
  computed: {    
    canToggle() {
      return true;
    }
  },
  async created(){
    await this.$get({
      url: `${this.$baseurl}/auth-bypass/switch`,
      headers: this.headers,
      success: (response) => {
        this.buttonState = response.data.data.value;
      },
      error: () => {

      },
    });
  },
  methods: {
    closeSwitchModal(){
      this.$refs.switchModal.close();
    },
    closePasswordModal(){
      this.$refs.passwordModal.close();
    },
    async toggleSwitch(){
      this.buttonState = (this.buttonState == 'off') ? 'on' : 'off';
      await this.$post({
        url: `${this.$baseurl}/auth-bypass/switch`,
        data: {
          state: this.buttonState,
        },
        headers: this.headers,
        success: () => {
          this.$refs.switchModal.open();
        },
        error: () => {
          // this.topUpError = "Top up failed";
        },
      });
    },
    async generatePassword(){
      if (!this.validateForm(this.form)) {
        return false;
      }
      this.form.error = false;
      this.form.loading = true;

      await this.$post({
        url: `${this.$baseurl}/auth-bypass/generate-password`,
        data: {
          email: this.form.data.email.value,
          duration: this.form.data.duration.value,
        },
        headers: this.headers,
        success: response => {
          this.password = response.data.data.password;
          this.$refs.passwordModal.open();
          this.form.data.email.value = '';
          this.form.data.duration.value = '';
        },
        error: error => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });

      this.form.loading = false;
    }
  },
};
</script>
